.product-shipment-details {
  font-size: 0.9375rem;
  margin-bottom: 2rem;

  &__headline {
    color: var(--color-grey-medium);
    margin-bottom: 0.625rem;
  }

  &__type {
    margin: 6px 0;
  }
}
