@import "../../styles/colors.scss";
@import "../../styles/breakpoint.mixin.scss";

.product-details {
  margin-bottom: 70px;

  &__meta {
    @include breakpoint("s") {
      margin-top: 2rem;
    }
  }

  &__ean {
    color: $color-grey-medium;
    font-size: 0.8125rem;
  }

  &__price {
    font-size: 1.5rem;
    margin-top: 0;
  }

  &__description {
    font-size: 0.9375rem;
    color: $color-grey-medium;
  }

  h1 {
    margin: 0;
  }

  hr {
    margin: 24px 0;
  }
}
