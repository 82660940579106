@import "../../styles/colors.scss";
@import "../../styles/breakpoint.mixin.scss";

.input-amount {
  label {
    display: block;
    margin-bottom: 6px;
  }

  input {
    display: block;
    margin: 0;
    border: 0;
    padding: 0;
    white-space: normal;
    background: none;
    text-align: center;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    max-width: 68px;
    padding: 15px 0 15px 15px;
    border: 1px solid $color-grey-blue;
    color: $color-grey-medium;
    font-size: 1rem;
    border-radius: 4px;
    background: #fff;

    @include breakpoint("s") {
      padding: 15px 10px;
    }
  }
}
