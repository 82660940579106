.userAvatar {
  cursor: pointer;

  &__content {
    display: flex;
    align-items: center;

    p {
      margin: 0 4px 0 0;
      color: var(--color-grey-medium);
    }

    img {
      margin-left: 14px;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      overflow: hidden;
    }
  }
}
