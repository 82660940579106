@import "../../styles/colors.scss";

.shipment-select {
  margin-bottom: 20px;

  &__item {
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #fff;
    cursor: pointer;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    transition: border-color 0.2s ease;

    &:not(:last-of-type) {
      margin-bottom: 12px;
    }

    &:hover {
      border-color: $color-sonnen-blue;
    }

    input {
      cursor: pointer;
      margin-left: 14px;
    }

    label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 14px 14px 14px 0;
      margin-left: 10px;
      cursor: pointer;
      user-select: none;

      p {
        margin: 0;
      }
    }
  }
}
