@import "../../styles/colors.scss";

.checkout-address-bar {
  margin-top: 20px;

  .checkout-address h4 {
    margin-top: 0;
    margin-bottom: 0.75em;
  }

  hr {
    margin: 48px auto;
    opacity: 0.25;
  }
}
