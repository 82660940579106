@import "../../styles/colors.scss";

.basket {
  margin-bottom: 30px;
  h1 {
    margin-top: 0;
    margin-bottom: 30px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    font-weight: 600;

    th {
      text-align: center;
      font-size: 0.8125rem;
      color: $color-silicium-blue;
      padding-bottom: 16px;

      &:first-of-type {
        text-align: left;
        padding-left: 10px;
      }
      &:last-of-type {
        text-align: right;
        padding-right: 10px;
      }
    }

    tbody tr.--hover:hover {
      background: $color-grey-light;
    }

    td {
      text-align: center;
      padding: 24px 0;
      border-top: 1px solid
        transparentize($color: $color-grey-medium, $amount: 0.75);
      border-bottom: 1px solid
        transparentize($color: $color-grey-medium, $amount: 0.75);

      &.--no-border {
        border-top: 0;
        border-bottom: 0;
      }

      &:nth-of-type(1),
      &:nth-of-type(2) {
        text-align: left;
      }

      &:first-of-type {
        padding-left: 10px;
      }

      &:last-of-type {
        text-align: right;
        padding-right: 10px;
      }
    }

    .basket__subtotal td {
      border-bottom: 0;
      padding-bottom: 4px;
    }

    .basket__shipment td {
      border-top: 0;
      padding-top: 4px;
      font-weight: 500;
      color: $color-grey-blue;
    }

    .basket__total td {
      border-bottom: 0;
    }
  }

  & table &__delete {
    display: inline-block;
    padding: 5px 5px;
    cursor: pointer;

    svg g {
      transition: stroke 0.2s ease;
    }

    &:hover svg g {
      stroke: $color-red;
    }
  }

  & table &__subtotal td {
    border-bottom: 0;

    &:hover {
      background: #fff;
    }

    span {
      margin-left: 80px;
    }
  }

  & table &__product__title {
    padding-left: 24px;
    width: 60%;

    a {
      color: $color-silicium-blue;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    span {
      display: block;
      color: $color-grey-medium;
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 500;
    }

    &__container {
      display: flex;
      align-items: center;

      p {
        margin: 0;
      }

      span {
        margin-left: 12px;
        color: $color-red;
        font-weight: 600;
        line-height: 1.1rem;
      }
    }
  }

  & table span.basket__product__comment {
    margin: 6px 0;
    font-size: 0.9rem;
  }

  &__product {
    &__image {
      width: 48px;
      height: 48px;
      overflow: hidden;
      border-radius: 4px;

      img {
        display: block;
        width: 100%;
        height: auto;
        margin-right: 24px;
      }
    }
  }
}
