.subheader__documents {
  height: 72px;
  background: #fff;
  box-shadow: 0 3px 4px -2px #c9cbd0;

  &__breadcrumb {
    display: flex;
    align-items: center;
    max-width: 1340px;
    height: 100%;
    margin: 0 auto;
    padding: 0 24px;

    &__item {
      a {
        color: var(--color-grey-medium);
        font-size: 0.875rem;
        text-decoration: none;

        &:visited {
          color: var(--color-grey-medium);
          text-decoration: none;
        }

        &:hover {
          color: var(--color-grey-dark);
        }
      }

      svg {
        margin: 0 10px;
      }

      &.--flex {
        display: flex;
        align-items: center;
      }
    }
  }
}
