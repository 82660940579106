@import "../../styles/colors.scss";

.page-branding {
  &__content {
    margin: 40px auto;

    a {
      color: #000;
      text-decoration: none;
      font-weight: 700;
    }
  }

  .vc_box_border {
    border: 1px solid $color-silicium-blue;
  }

  .sonnen-button {
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    border: 2px solid $color-silicium-blue;
    padding: 12px 40px;
    font-weight: 700;
    color: #000;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: none;
      border-color: $color-sonnen-blue;
      color: $color-sonnen-blue;

      svg {
        rect,
        path,
        line,
        polyline {
          stroke: $color-sonnen-blue;
        }
      }
    }

    svg {
      display: block;
      user-select: none;
      margin-right: 10px;
      width: 16px;
      height: 16px;
    }

    span {
      font-weight: 500;
      white-space: nowrap;
      user-select: none;
    }
  }

  .guideline__title {
    margin: 0 0 1rem 0;
    border-bottom: 1px solid #c9cbd0;
  }
}
