@import "../../styles/colors.scss";
@import "../../styles/breakpoint.mixin.scss";

.card {
  height: 100%;

  @include breakpoint("s") {
    margin-bottom: 20px;
  }

  &__inner {
    height: 100%;
    background: #ffffff;
    padding: 60px 66px;
    border: 1px solid #fff;
    border-radius: 3px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
      border: 1px solid #4dcff0;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
    }

    @include breakpoint("s") {
      align-items: center;
      padding: 20px;
    }
  }

  &.--text {
    &-center .card__inner {
      text-align: center;
    }
    &-left .card__inner {
      text-align: left;
    }
  }

  &.--headline {
    &-small h2 {
      font-size: 1.0625rem;

      @include breakpoint("s") {
        font-size: 0.875rem;
        font-weight: 600;
      }
    }

    &-medium h2 {
      font-size: 1.5rem;

      @include breakpoint("s") {
        font-size: 1.125rem;
        line-height: 1.875rem;
      }
    }
  }

  &.--image {
    &-cover {
      .card {
        &__inner {
          padding: 0;
        }
        &__info {
          padding: 18px 24px;
        }
        &__image {
          background-size: cover;
          padding-top: 70%;
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
        }
      }

      h2,
      p {
        margin: 0;
      }
    }
  }

  &__image {
    padding-top: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    user-select: none;

    @include breakpoint("s") {
      width: 100px;
      height: 100px;
      padding-top: 0;
    }
  }

  a {
    text-decoration: none;
    color: $color-silicium-blue;
  }

  &__info {
    @include breakpoint("s") {
      text-align: left;
    }
  }

  h2 {
    font-weight: 500;
    user-select: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  p {
    opacity: 0.5;
    font-size: 0.9375rem;
    user-select: none;
  }
}
