@import "../../styles/colors.scss";
@import "../../styles/breakpoint.mixin.scss";

.login {
  width: 100vw;
  background-size: cover;
  background-position: 50% 50%;

  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    height: 100%;

    &__inner {
      width: 400px;
      background: #fff;
      margin: 20px auto;

      @include breakpoint("s") {
        width: 90vw;
      }
    }

    &__header {
      background: $color-silicium-blue;
      padding: 20px 24px;
      margin-bottom: 20px;

      img {
        display: block;
        width: 120px;
        height: auto;
      }
    }

    &__intro {
      padding: 0 48px;
      text-align: center;

      @include breakpoint("s") {
        padding: 0 30px;
      }

      h1 {
        font-size: 1.5rem;
        line-height: 1.875rem;
        margin: 1.25rem 0 1.5rem;
      }

      img {
        display: block;
        width: 140px;
        height: auto;
        margin: 0 auto;
      }
    }

    form {
      padding: 0 48px 48px;

      @include breakpoint("s") {
        padding: 0 30px 30px;
      }
    }

    &__register,
    &__reset {
      font-size: 0.8rem;
      padding: 0 48px 10px;
      text-align: center;

      @include breakpoint("s") {
        padding: 0 30px 10px;
      }

      a {
        font-weight: 600;
        text-decoration: none;
        color: $color-silicium-blue;

        @include breakpoint("s") {
          display: block;
        }

        &:hover {
          color: $color-sonnen-blue;
        }
      }
    }

    &__register {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}
