@import "../../styles/colors.scss";

.order-item-details {
  padding: 50px 0;

  h4 {
    color: $color-grey-medium;
  }

  .address-field p {
    color: $color-grey-blue;
  }

  &__contact {
    font-size: 0.866rem;
  }
}
