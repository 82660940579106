@import "../../styles/colors.scss";

.checkout {
  &__basket-overview {
  }

  &__address-overview {
    min-height: calc(100vh - 156px - 76px);
    padding-right: 0;
    background: $color-grey-light;
  }
}
