@import "../../styles/colors.scss";

.address-field {
  p {
    font-size: 1.15rem;
    color: $color-grey-medium;
    margin: 0.3rem 0;
  }

  &.--small {
    p {
      font-size: 1rem;
      line-height: 1.1rem;
    }
  }
}
