.order-complete {
  margin: 100px 0;

  &__image {
    width: 200px;
    margin: 0 auto;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__buttons {
    margin-top: 50px;
    display: flex;
    justify-content: center;

    a:first-of-type {
      margin-right: 20px;
    }
  }
}
