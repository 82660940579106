@import "../../styles/colors.scss";

.order-item-overview {
  td {
    padding: 20px 0;
    border-bottom: 1px solid
      transparentize($color: $color-grey-medium, $amount: 0.75);

    &.--no-border {
      border-bottom: 0;
      width: 34%;
    }
  }

  &__title {
    font-weight: 600;
  }

  &__amount {
    text-align: center;
  }

  &__price {
    text-align: right;
  }

  &__sum,
  &__shipping,
  &__total {
    td {
      border-bottom: none;
    }

    td:last-of-type {
      text-align: right;
    }
  }

  &__sum td {
    padding-bottom: 4px;
    color: $color-grey-blue;
  }

  &__shipping {
    td {
      color: $color-grey-blue;
      padding-top: 4px;

      &:not(.--no-border) {
        border-bottom: 1px solid
          transparentize($color: $color-grey-medium, $amount: 0.75);
      }
    }
  }

  &__total td {
    padding-top: 30px;
    font-weight: 600;
  }
}
