@import "../../styles/colors.scss";
@import "../../styles/breakpoint.mixin.scss";

.order-overview {
  margin-bottom: 40px;

  table {
    width: 100%;
    border-collapse: collapse;

    th {
      text-align: left;
      font-size: 0.866rem;
      font-weight: 600;
      padding-bottom: 15px;

      &:first-of-type {
        padding-left: 15px;

        @include breakpoint("s") {
          padding-right: 15px;
        }
      }
    }

    tr td:first-of-type {
      padding-left: 15px;

      @include breakpoint("s") {
        padding-right: 15px;
      }
    }
  }
}
