.mainHeader .tippy-box {
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24);
  border-radius: 2px;

  .tippy-arrow:before {
    border-bottom-color: #fff !important;
    left: 50% !important;
    transform: translateX(-50%);
    margin: 0 !important;
  }
}

.userDropdown {
  padding: 20px;

  span {
    color: var(--color-silicium-blue);
    cursor: pointer;
    padding: 2px;

    &:hover {
      font-weight: 600;
    }
  }
}
