@import "../../styles/colors.scss";
@import "../../../node_modules/swiper/swiper.scss";
@import "../../../node_modules/swiper/components/pagination/pagination.scss";
@import "../../../node_modules/swiper/components/navigation/navigation.scss";

.product-images {
  position: relative;

  &.--single {
    img {
      max-width: 100%;
      width: auto;
      max-height: 70vh;
      margin: 0 auto;
    }
  }

  .swiper-container {
    background: #f5f5f5;
  }

  .swiper-pagination {
    &-bullet,
    &-bullet-active {
      background: $color-silicium-blue;
    }
  }

  .swiper-button {
    &-next,
    &-prev {
      background: #fff;
      width: 64px;
      height: 64px;
      border-radius: 100%;
      transition: box-shadow 0.3s ease;

      &:hover {
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
      }

      &:after {
        font-size: 1.5rem;
        color: $color-silicium-blue;
      }
    }

    &-next:after {
      margin-left: 5px;
    }

    &-prev:after {
      margin-right: 5px;
    }
  }

  &__download {
    position: absolute;
    display: flex;
    align-items: center;
    height: auto;
    width: auto;
    bottom: 40px;
    left: 50%;
    padding: 10px 12px;
    background-color: #fff;
    color: $color-silicium-blue;
    text-decoration: none;
    font-weight: 600;
    border: 0;
    border-radius: 4px;
    transform: translateX(-50%);
    z-index: 2;
    transition: background-color 0.3s ease;

    .--single & {
      bottom: 30px;
    }

    &:hover {
      background-color: $color-sonnen-blue;
    }

    svg {
      width: 20px;
      height: auto;
      margin-right: 8px;
    }
  }
}
