@import "../../styles/colors.scss";

.order-status {
  p {
    position: relative;
    padding-left: 16px;

    &:before {
      content: "";
      position: absolute;
      top: 5px;
      left: 0;
      width: 10px;
      height: 10px;
      border-radius: 100%;
    }
  }

  &.--received p:before {
    background: $color-orange;
  }
  &.--processed p:before {
    background: $color-yellow;
  }
  &.--completed p:before {
    background: $color-green;
  }
  &.--cancelled p:before {
    background: $color-red;
  }
}
