@import "./breakpoint.mixin.scss";

$grid-gutter-width: 48px;

$container-max-widths: (
  sm: 1340px,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 770px,
  lg: 1024px,
  xl: 1340px,
);

@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "./overwrites.mixin.scss";

@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/utilities";

@import "./normalize.scss";

@import "./colors.scss";
@import "./fonts.scss";
@import "./typography.scss";
@import "./forms.scss";
@import "./helpers.scss";
@import "./toastify.scss";
@import "./tables.scss";
@import "./vc-elements/sonnen-button.scss";
@import "./vc-elements/sonnen-hero.scss";
// @import './tables.scss';
// @import './helpers.scss';
// @import './vc_overwrites.scss';

body {
  margin: 0;
  padding: 0;
  background-color: #fff;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

* {
  box-sizing: border-box;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

figure {
  margin: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #979797;
  margin: 1em 0;
  padding: 0;
}

.mainHeader + .container {
  min-height: calc(100vh - 76px - 156px);
}
