@import "../../styles/colors.scss";

.basket-overview {
  &__product {
    display: flex;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid
      transparentize($color: $color-grey-medium, $amount: 0.75);

    &__image {
      position: relative;
      width: 48px;
      height: 48px;
      margin-right: 20px;

      &__container {
        width: 48px;
        height: 48px;
        border-radius: 3px;
        overflow: hidden;
      }

      &__amount {
        position: absolute;
        top: -12px;
        right: -12px;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        background: $color-silicium-blue;
        color: #fff;
        text-align: center;
        font-size: 0.8rem;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }

    &__details {
      flex: 1;
      overflow: hidden;

      p {
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__variant {
      font-size: 0.875rem;
      color: $color-grey-medium;
    }

    &__price {
      font-size: 0.8rem;
    }
  }

  &__amount,
  &__shipping {
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;
    font-weight: 600;
  }

  &__shipping {
    font-weight: 500;
  }

  &__subtotal {
    color: $color-grey-blue;
    margin-bottom: 20px;
    border-bottom: 1px solid
      transparentize($color: $color-grey-medium, $amount: 0.75);
    width: 66%;
    margin-left: auto;
  }

  &__total {
    width: 66%;
    margin-left: auto;
    span {
      font-size: 1rem;
      font-weight: 600;
    }
  }
}
