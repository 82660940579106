@import "../../styles/breakpoint.mixin.scss";

.page-shop {
  .cardContainer {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: 40px;

    @include breakpoint("s") {
      flex-direction: column;
    }
  }

  &__tax-info {
    color: var(--color-grey-medium);
    font-size: 0.75rem;
    font-style: italic;
    text-align: right;

    @include breakpoint("s") {
      text-align: left;
    }
  }
}
