@import "../../styles/colors.scss";

.input-text {
  label {
    display: block;
    margin-bottom: 6px;
  }

  input {
    display: block;
    width: 100%;
    padding: 15px;
    border: 1px solid $color-grey-blue;
    color: $color-silicium-blue;
    font-size: 0.875rem;
    border-radius: 4px;

    &::placeholder {
      color: $color-grey-medium;
      font-size: 1rem;
    }
  }
}
