@import "../../styles/colors.scss";

.mainHeader {
  position: sticky;
  top: -1px;
  background: $color-silicium-blue;
  z-index: 5;

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1340px;
    margin: 0 auto;
    height: 84px;
    padding: 24px;
  }

  &__branding {
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    img {
      display: block;
      height: 100%;
      max-height: 32px;
      width: auto;
    }

    span {
      border-left: 1px solid $color-grey-blue;
      margin-left: 22px;
      padding-left: 22px;
      font-size: 1.125rem;
      color: $color-grey-blue;
    }
  }

  &__navigation {
    display: flex;
    align-items: center;

    a {
      color: #fff;
      text-decoration: none;

      &:not(:last-of-type) {
        margin-right: 2.8rem;
      }

      &.active,
      &:hover {
        color: $color-sonnen-blue;
      }
    }

    li {
      display: inline-block;
    }

    &__user {
      display: flex;
      align-items: center;
      margin-left: 26px;

      &:before {
        content: "|";
        color: var(--color-grey-medium);
        font-size: 1.5em;
        display: inline-block;
        margin-right: 20px;
      }
    }
  }
}
