@import "../../styles/colors.scss";

.product-detail-list {
  font-size: 0.9375rem;
  margin-bottom: 2rem;

  &__headline {
    color: $color-grey-medium;
    margin-bottom: 0.625rem;
  }
  ul {
    padding-left: 18px;

    li {
      margin: 10px 0;
    }
  }
}
