label {
  color: $color-grey-dark;
  font-size: 0.875rem;
  font-weight: 600;
}

.button-base {
  background: $color-silicium-blue;
  color: #fff;
  border: 2px solid $color-silicium-blue;
  border-radius: 4px;
  font-weight: 700;
  padding: 12px;
  width: 100%;
  user-select: none;
  cursor: pointer;
  transition: all 0.25s ease;

  &:hover,
  &:focus,
  &:active {
    background: $color-sonnen-blue;
    border-color: $color-sonnen-blue;
  }

  &.--disabled,
  &:disabled {
    pointer-events: none;
    opacity: 0.2;
  }

  &.--outline {
    background: transparent;
    color: $color-silicium-blue;

    &:hover,
    &:focus,
    &:active {
      background: $color-sonnen-blue;
      border-color: $color-sonnen-blue;
      color: #fff;
    }
  }

  &.--red {
    &.--outline {
      border-color: $color-red;
      color: $color-red;

      &:hover,
      &:active,
      &:focus {
        background: $color-red;
        color: #fff;
      }
    }
  }
}

button {
  @extend .button-base;
  height: 100%;
}

a.button {
  @extend .button-base;

  display: block;
  text-align: center;
  text-decoration: none;
}
