@import "../../styles/breakpoint.mixin.scss";

.documents-table {
  table {
    width: 100%;
    border-collapse: collapse;

    tbody {
      tr {
        transition: background-color 0.3s ease-out;

        &:hover {
          background-color: #f9f9fa;
        }

        &[data-is-folder="true"] {
          cursor: pointer;
        }
      }

      a {
        text-decoration: none;
        color: var(--color-ground-black);
      }
    }

    th {
      color: var(--color-ground-black);
      font-weight: bold;
      font-size: 0.825rem;
      padding-bottom: 25px;

      &:last-of-type {
        padding-right: 15px;
      }
    }

    td {
      border-top: 1px solid #e5e5e5;
      padding: 28px 0;
      color: var(--color-ground-black);

      &:first-of-type {
        padding-left: 15px;
      }

      &:last-of-type {
        padding-right: 15px;
      }
    }
  }

  &__name {
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      cursor: pointer;
      opacity: 0.5;
      transition: opacity 0.3s ease-in-out;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__file-icon {
    display: block;
    width: 28px;
    height: auto;

    @include breakpoint("s") {
      margin-right: 12px;
    }
  }

  &__meta {
    display: flex;
    margin-top: 24px;
    color: var(--color-grey-medium);

    p {
      margin: 0;

      &:not(:last-of-type) {
        margin-right: 30px;
      }
    }
  }

  .--text-left {
    text-align: left;
  }
  .--text-center {
    text-align: center;
  }
  .--text-right {
    text-align: right;
  }
}
