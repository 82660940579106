:root {
  --color-ground-black: #222222;
  --color-silicium-blue: #282e44;
  --color-sonnen-blue: #4dcff0;
  --color-grey-blue: #5e6373;
  --color-grey-light: #f9f9fa;
  --color-grey-medium: #9396a1;
  --color-grey-dark: #4a4a4a;
  --color-orange: #ff8f38;
  --color-yellow: #ffd14a;
  --color-green: #a1d442;
  --color-red: #ff4a4a;
}

$color-ground-black: #222222;
$color-silicium-blue: #282e44;
$color-sonnen-blue: #4dcff0;
$color-grey-blue: #5e6373;
$color-grey-light: #f9f9fa;
$color-grey-medium: #9396a1;
$color-grey-dark: #4a4a4a;
$color-orange: #ff8f38;
$color-yellow: #ffd14a;
$color-green: #a1d442;
$color-red: #ff4a4a;
