@import "../../styles/colors.scss";

.order-item {
  cursor: pointer;

  &:hover {
    background: $color-grey-light;
  }

  td {
    padding: 28px 0;
    border-top: 1px solid
      transparentize($color: $color-grey-medium, $amount: 0.75);
  }

  &__id {
    font-weight: 700;
  }
}

.order-item-toggle {
  display: none;
  background: $color-grey-light;

  &.--visible {
    display: table-row;
  }
}
