@import "./colors.scss";

.Toastify {
  &__toast {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    min-height: 84px;
    justify-content: center;

    &-container {
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
      padding: 0;
      transform: translateX(0);
    }

    &-body {
      font-family: "BrandonText", -apple-system, BlinkMacSystemFont,
        Helvetica Neue, sans-serif;
      width: 100%;
      max-width: 1340px;
      font-size: 1.1rem;
      font-weight: 600;
      text-align: center;
      color: $color-silicium-blue;
    }

    &--info {
      background-color: $color-sonnen-blue;

      a {
        color: $color-silicium-blue;
      }
    }
  }

  &__close-button {
    width: 32px;
    opacity: 0.8;
    align-self: center;

    &:hover {
      background: transparent;
    }

    svg {
      width: 18px;
      height: 22px;
      fill: $color-silicium-blue;
    }
  }

  &__progress-bar {
    background-color: transparentize(
      $color: $color-silicium-blue,
      $amount: 0.5
    );
  }
}
