@import "../../styles/colors.scss";

.input-select {
  label {
    display: block;
    margin-bottom: 6px;
  }

  select {
    display: block;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    padding: 15px;
    border: 1px solid #5e6373;
    color: #9396a1;
    font-size: 1rem;

    &::-ms-expand {
      display: none;
    }
  }

  &--hidden {
    height: 0;
    opacity: 0;
    outline: 0;
    border: 0;
    width: 100%;
  }
}
