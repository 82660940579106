@import "../../styles/colors.scss";

.back-button {
  display: inline-block;
  cursor: pointer;

  span {
    color: $color-grey-medium;
  }
}
