@import "../../styles/colors.scss";

.subheader__default {
  height: 72px;
  background: #fff;
  box-shadow: 0 3px 4px -2px #c9cbd0;

  &__wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    height: 100%;
  }

  &__item {
    height: 100%;
    padding: 24px;
    color: $color-grey-blue;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
      color: $color-silicium-blue;
    }

    &.active {
      color: $color-silicium-blue;
    }
  }
}
