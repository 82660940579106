@import "../../styles/colors.scss";

.footer {
  height: 76px;
  margin-top: auto;
  background: #ededed;
  font-size: 0.875rem;

  a {
    color: $color-grey-medium;
    text-decoration: none;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1340px;
    height: 100%;
    margin: 0 auto;
    padding: 0 24px;
  }

  &__legal {
    color: $color-grey-medium;
  }
}
