@import "./breakpoint.mixin.scss";

.wpb_vc_table {
  margin: 24px 0;

  @include breakpoint("s") {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    tr {
      td {
        padding: 12px;
        border-bottom: 1px solid #000;
      }

      &.vc-th td {
        font-weight: 700;
        padding: 6px 12px;
      }
    }
  }
}
