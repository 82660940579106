.popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  padding: 10vh 10vw;
  background: rgba(255, 255, 255, 0.3);

  &__close {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50px;
    right: 50px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background: #fff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;

    span {
      margin-left: 4px;
    }
  }

  img {
    display: block;
    width: 80vw;
    height: 80vh;
    margin: 0 auto;
    object-fit: contain;
  }
}
