.sonnen-hero {
  margin: 80px auto;

  &__wrapper {
    position: relative;
    padding: 63px 0;
  }

  &__content {
    padding-top: 65px;
    padding-bottom: 65px;
    border: 2px solid $color-sonnen-blue;
    z-index: 2;

    &__caption {
      text-transform: uppercase;
      color: $color-sonnen-blue;
      margin-top: 0;
      font-weight: bold;
      font-size: 0.8rem;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    z-index: 1;
    background-position: 50% 50%;
    background-size: cover;

    &.--left {
      right: auto;
      left: 24px;
    }

    &.--right {
      left: auto;
      right: 24px;
    }
  }

  .sonnen-button {
    margin-top: 2rem;
  }
}
