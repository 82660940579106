@import "../../styles/colors.scss";

.subheader {
  position: relative;
  height: 72px;
  background: #fff;
  box-shadow: 0 3px 4px -2px #c9cbd0;

  &:after,
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 20px;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 1) 28%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &:before {
    right: auto;
    left: 0;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 28%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1340px;
    height: 100%;
    margin: 0 auto;
    padding: 0 24px;
  }

  &__nav {
    display: flex;
    height: 100%;
  }

  &__item {
    height: 100%;
    padding: 24px;
    color: $color-grey-blue;
    text-decoration: none;
    border-bottom: 4px solid #fff;
    transition: border-color 0.2s ease, color 0.3s ease;

    &:hover {
      color: $color-silicium-blue;
    }

    &.active {
      border-bottom-color: $color-sonnen-blue;
      color: $color-silicium-blue;
    }

    &:not(:last-of-type) {
      margin-right: 24px;
    }

    &__content {
      display: flex;
      align-items: center;

      img,
      svg {
        display: block;
        max-width: 24px;
        height: auto;
        margin-right: 10px;
      }
    }
  }

  &__basket {
    display: flex;
    align-items: center;
    height: 100%;

    svg {
      margin-right: 10px;
    }
  }

  p {
    margin: 0;
    color: $color-grey-blue;
    font-size: 0.875rem;
  }
}
