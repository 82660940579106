.sonnen-button {
  display: inline-block;
  border-radius: 4px;
  text-decoration: none;
  text-align: center;
  user-select: none;

  &:hover {
    text-decoration: none;
  }

  &.--filled {
    padding: 16px 10px;
    min-width: 300px;
    background-color: $color-silicium-blue;
    color: #fff;
    font-weight: bold;

    &:hover {
      color: $color-silicium-blue;
      background-color: $color-sonnen-blue;
    }
  }

  &.--outline {
    padding: 7px 10px;
    min-width: 185px;
    background-color: #fff;
    color: $color-silicium-blue;
    border: 2px solid $color-silicium-blue;
    font-size: 0.875rem;
    font-weight: 600;

    &:hover {
      border-color: $color-sonnen-blue;
      color: $color-sonnen-blue;
    }
  }
}
